import React, { useRef, useState, useEffect } from "react"
import LoadingBar from "react-top-loading-bar"
import { Stage, Layer, Line } from "react-konva"
import Popup from "reactjs-popup"
import { Drawer } from "rsuite"
import "reactjs-popup/dist/index.css"
import "rsuite/dist/rsuite.min.css"
import { Buffer } from "buffer"

import rightHandBackGroundSpiral from "../Canvas/images/rightHandBackGroundSpiral.png"
import {
  refreshTokens,
  refreshTokensWithUserInfo
} from "../Authentication/refresh"
import { handleHistogram } from "../Histogram/handleHistogram"
import {
  TEST_ENDPOINT,
  DOWNLOAD_RECORD_ENDPOINT,
  INVALIDTOKEN,
  INVALIDDATAFORMAT,
  INVALIDTIMESTAMP,
  APICONNECTIONERROR,
  TESTPERFORMERROR
} from "../../metadata"
import Histogram from "../Histogram/Histogram"
import "../../App.css"
import Navbar from "../Navbar"
import infoLogo from "../../assets/info.svg"
import { logUserOut } from "../Authentication/logUserOut"

export const Canvas = () => {
  const [tool, setTool] = useState("pen")
  const [lines, setLines] = useState([])
  const [userId, setUserId] = useState("")
  const [result, setResult] = useState([])
  const [prediction, setPrediction] = useState("") //useState([])
  const [isParkinsonPatient, setIsParkinsonPatient] = useState(false)
  const isDrawing = useRef(false)
  const canvasRef = useRef()
  const [dominantHand, setDominantHand] = useState("notSelected")
  const [drawnHand, setDrawnHand] = useState("notSelected")
  const [loadingBarProgress, setLoadingBarProgress] = useState(0)
  // const [parkinsonRecordList, setParkinsonRecordList] = useState([])
  // const [parkinsonRecordLoaded, setParkinsonRecordLoaded] = useState(false)
  const isFirstRender = useRef(true)
  const [data, setData] = useState([0, 0, 0, 0, 0])
  const [timestamps, setTimestamps] = useState([0, 0, 0, 0, 0])

  const [openPastRecord, setOpenPastRecord] = useState(false)

  const [isOpen, setIsOpen] = useState(false)
  useEffect(() => {
    // call authentication functions when component mounts
    if (isFirstRender.current) {
      isFirstRender.current = false
      // const { sub, username } = await refreshTokensWithUserInfo()
      // setUserId(sub)
      refreshTokensWithUserInfo().then((sub) => {
        setUserId(sub)

        //populate histogram after user is Authenticated
        handleHistogram(sub, "canvas").then((predictions) => {
          setData(predictions.predictions)
          setTimestamps(predictions.timestamps)
        })
      })
    }
  }, [])

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  const handleOpenPastRecord = () => {
    setOpenPastRecord(true)
  }

  const handleMouseDown = (e) => {
    isDrawing.current = true
    const pos = e.target.getStage().getPointerPosition()
    setLines([...lines, { tool, points: [pos.x, pos.y], time: Date.now() }])
    setResult([{ x: pos.x, y: pos.y, timestamp: Date.now() }])
  }

  const handleMouseMove = (e) => {
    // no drawing - skipping
    if (!isDrawing.current) {
      return
    }
    const stage = e.target.getStage()
    const point = stage.getPointerPosition()
    let lastLine = lines[lines.length - 1]
    // add point
    lastLine.points = lastLine.points.concat([point.x, point.y])
    // replace last
    lines.splice(lines.length - 1, 1, lastLine)
    setLines(lines.concat())
    setResult([...result, { x: point.x, y: point.y, timestamp: Date.now() }])
  }

  const handleMouseUp = () => {
    isDrawing.current = false
  }

  const handleGetPastResult = async () => {
    // const data = {
    //   type: "canvas",
    //   userId: userId,
    //   token: localStorage.getItem("access_token")
    // }

    // const resultRes = await fetch(LIST_PAST_TEST_INFO_ENDPOINT, {
    //   method: "POST",
    //   mode: "cors",
    //   headers: {
    //     "Access-Control-Allow-Origin": "*",
    //     "Access-Control-Allow-Methods": "POST, OPTIONS",
    //     "Access-Control-Allow-Headers": "Content-Type, Authorization",
    //     // "Access-Control-Allow-Credentials": true,
    //     "Content-Type": "application/json"
    //   },
    //   body: JSON.stringify(data)
    // })

    // if (resultRes.statusCode !== 200 && resultRes.status !== 200) {
    //   alert("Error happens when loading previous records")
    //   return
    // }
    // const jsonResponse = await resultRes.json()

    // const response = jsonResponse.map((record) => {
    //   const date = new Date(record.timestamp)
    //   return {
    //     ...record,
    //     prediction: `${(Number(record.prediction) * 100.0).toFixed(2)}%`,
    //     date: `${date.toLocaleDateString("en-US")} ${date.toLocaleTimeString(
    //       "en-US"
    //     )}`
    //   }
    // })

    const predictions = await handleHistogram(userId, "canvas")
    setData(predictions.predictions)
    setTimestamps(predictions.timestamps)

    // setParkinsonRecordList(response)
    // setParkinsonRecordLoaded(true)
    await refreshTokens()
  }

  const handleClearPastResult = () => {
    // setParkinsonRecordList([])
    // setParkinsonRecordLoaded(false)
    setOpenPastRecord(false)
  }

  const handleDownload = async (timestamp) => {
    const data = {
      type: "canvas",
      userId: userId,
      token: localStorage.getItem("access_token"),
      timestamp: Number(timestamp)
    }

    const resultRes = await fetch(DOWNLOAD_RECORD_ENDPOINT, {
      method: "POST",
      mode: "cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "POST, OPTIONS",
        "Access-Control-Allow-Headers": "Content-Type, Authorization",
        // "Access-Control-Allow-Credentials": true,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    })
    if (resultRes.statusCode !== 200 && resultRes.status !== 200) {
      return
    }

    const files = await resultRes.json()

    for (const file of files) {
      const newBlob = new Blob([Buffer.from(file.fileBody)])

      if (file.fileName !== "prediction.txt") {
        const coordinatesValue = JSON.parse(
          await newBlob.text()
        ).coordinates.map((coordinate) => ({
          x: coordinate.x,
          y: coordinate.y
        }))
        const imgWidth = 600,
          imgHeight = 450
        const imgBuffer = new Uint8Array(imgWidth * imgHeight * 4)

        for (const cv of coordinatesValue) {
          const x = cv.x
          const y = cv.y

          const position = (y * imgWidth + x) * 4
          // console.log(position)
          imgBuffer[position] = 255
          // imgBuffer[position + 1] = 255
          // imgBuffer[position + 2] = 255
          imgBuffer[position + 3] = 255
        }
        // https://stackoverflow.com/questions/22823752/creating-image-from-array-in-javascript-and-html5
        // create off-screen canvas element
        var canvas = document.createElement("canvas"),
          ctx = canvas.getContext("2d")

        canvas.width = imgWidth
        canvas.height = imgHeight

        // create imageData object
        var idata = ctx.createImageData(imgWidth, imgHeight)

        // set our buffer as source
        idata.data.set(imgBuffer)

        // update canvas with new data
        ctx.putImageData(idata, 0, 0)

        const dataUri = canvas.toDataURL()

        const link = document.createElement("a")
        link.download = `coordinates_${timestamp}.png`
        link.style.display = "none"
        link.href = dataUri
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        // clean up Url
        window.URL.revokeObjectURL(dataUri)
      } else {
        const link = document.createElement("a")
        link.download = `prediction_${timestamp}.txt`
        link.style.display = "none"
        const blobUrl = window.URL.createObjectURL(newBlob)
        link.href = blobUrl
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        // clean up Url
        window.URL.revokeObjectURL(blobUrl)
      }
    }

    await refreshTokens()
  }

  const handleReset = () => {
    setLines([])
    setResult([])
  }
  const handleSubmit = async (prevPrediction) => {
    if (userId === "") {
      alert("Drawing was not submitted!\nPlease login before submitting!")
      return
    } else if (userId === undefined) {
      alert("Drawing was not submitted!\nPlease login before submitting!")
      return
    } else if (drawnHand === "notSelected") {
      alert(
        "Drawing was not submitted!\nPlease select which hand did you use to draw this diagram before submitting!"
      )
      return
    } else if (dominantHand === "notSelected") {
      alert(
        "Drawing was not submitted!\nPlease select your dominant hand before submitting!"
      )
      return
    }

    // remove previous prediction
    const previousResultToken =
      prevPrediction && prevPrediction[0] !== "L"
        ? ` (Previous prediction: ${prevPrediction})`
        : ""
    setPrediction(`Loading...${previousResultToken}`)

    const innerData = {
      "time-uploaded": Date.now(),
      coordinates: result,
      userId: userId,
      dominantHand: dominantHand,
      drawnHand: drawnHand,
      isParkinsonPatient: isParkinsonPatient
    }

    setLoadingBarProgress(loadingBarProgress + 20)
    const accessToken = localStorage.getItem("access_token")

    const data = {
      data: innerData,
      userId: userId,
      type: "canvas",
      token: accessToken,
      username: localStorage.getItem("username")
    }

    try {
      const testRes = await fetch(TEST_ENDPOINT, {
        method: "POST",
        mode: "cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST, OPTIONS",
          "Access-Control-Allow-Headers": "Content-Type, Authorization",
          // "Access-Control-Allow-Credentials": true,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      })

      setLoadingBarProgress(loadingBarProgress + 50)

      // console.log(testRes.json()) // Note: json for success
      // console.log(await testRes.text()) // Note: text for errors
      const response = await testRes.json()
      if (response.statusCode !== 200) {
        setPrediction("")
        setLoadingBarProgress(100)

        if (response.type === INVALIDTOKEN) {
          // sign out
          alert("Your session is expired! Please log-in again!")
          logUserOut()
        } else if (
          response.type === INVALIDDATAFORMAT ||
          response.type === INVALIDTIMESTAMP
        ) {
          alert("Please follow the instruction to complete the spiral drawing!")
          handleReset()
        } else if (
          response.type === APICONNECTIONERROR ||
          response.type === TESTPERFORMERROR
        ) {
          alert("Service is on maintainence right now, please come back later!")
        }

        return
      }

      // precentage
      const pred_acc = (Number(response.body) * 100.0).toFixed(2)
      var evaluate = "Low"
      if (pred_acc >= 60) {
        evaluate = "High"
      } else if (pred_acc >= 30) {
        evaluate = "Moderate"
      }

      setPrediction(`${pred_acc}% - ${evaluate} possibility of Parkinson's`)
      setLoadingBarProgress(100)
      await refreshTokens()
    } catch (e) {
      console.log(e)
      setPrediction("")
      setLoadingBarProgress(100)
      return
    }
  }

  return (
    <div
      ref={canvasRef}
      style={{
        width: "100%"
      }}
      id="canvas_test"
    >
      {/* <div></div>
      <div style={{ "background-color": "black", width: "100%", height: "120px" }}>
        <Navbar toggle={toggle} />
      </div>
      <div></div> */}

      <Drawer
        placement={"left"}
        open={openPastRecord}
        onClose={() => handleClearPastResult()}
        onOpen={async () => await handleGetPastResult()}
        style={{ width: "800px" }}
      >
        <Drawer.Header>
          <div>
            Please click on the bar or the date to download the corresponding
            record.
          </div>
        </Drawer.Header>
        <Drawer.Body>
          <Histogram
            data={data}
            timestamps={timestamps}
            width="600px"
            handleDownload={handleDownload}
          />
        </Drawer.Body>
      </Drawer>

      <Navbar
        style={{ width: "100%", height: "80px" }}
        toggle={toggle}
        isStatic={true}
      />
      <div style={{ width: "100%", height: "80px" }}></div>

      {/* <div
          style={{
            flexGrow: 1,
            width: "100%"
          }}
        >
          <Histogram
            data={data}
            timestamps={timestamps}
            width="840px"
            height="900px"
          />
        </div> */}

      <div
        style={{
          display: "flex",
          width: "100%"
        }}
      >
        <div
          style={{
            "margin-left": "auto",
            "margin-right": "auto"
          }}
        >
          <div
            style={{
              height: `575px`,
              width: `600px`,
              backgroundImage: `url(${rightHandBackGroundSpiral})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "80%",
              float: "left",
              fontsize: "250px"
            }}
          >
            <br />
            <div>
              Please draw in the box below, tracing the dotted spiral in the
              direction of the arrows. After finishing, select the appropriate
              options before submitting.
            </div>
            <br />
            <Stage
              width={600}
              height={450}
              onMouseDown={handleMouseDown}
              onMousemove={handleMouseMove}
              onMouseup={handleMouseUp}
            >
              <Layer>
                {lines.map((line, i) => (
                  <Line
                    key={i}
                    points={line.points}
                    stroke="#df4b26"
                    strokeWidth={2}
                    tension={0.5}
                    lineCap="round"
                    lineJoin="round"
                    globalCompositeOperation={
                      line.tool === "eraser" ? "destination-out" : "source-over"
                    }
                  />
                ))}
              </Layer>
            </Stage>
            {userId ? (
              <span></span>
            ) : (
              <a
                class="text-with-break"
                href="https://neuroprior-users.auth.us-east-1.amazoncognito.com/login?client_id=7p9vmm4fmapn3a2sgim1e0f2hm&response_type=code&scope=email+openid+phone&redirect_uri=https%3A%2F%2Fwww.neuroprior.net"
              >
                You are not logged in.
              </a>
            )}
            <br />
            <select
              className="custom-select"
              value={drawnHand}
              onChange={(e) => {
                setDrawnHand(e.target.value)
              }}
            >
              <option value="notSelected">
                Select the hand you drew with:
              </option>
              <option value="right">This was drawn with my right hand</option>
              <option value="left">This was drawn with with left hand</option>
            </select>
            <select
              className="custom-select"
              value={dominantHand}
              onChange={(e) => {
                setDominantHand(e.target.value)
              }}
            >
              <option value="notSelected">Select your dominant hand</option>
              <option value="right">My dominant hand is right hand</option>
              <option value="left">My dominant hand is left hand</option>
            </select>
            <br />
            <input
              type="checkbox"
              id="isDiagnosedCheckbox"
              name="drone"
              checked={isParkinsonPatient}
              onChange={() => setIsParkinsonPatient(!isParkinsonPatient)}
              // onChange={() => console.log("top" + canvasRef.current.offsetTop + "left" + canvasRef.current.offsetLeft)}
            />
            <label
              htmlFor="isDiagnosedCheckbox"
              className="custom-checkbox-label"
            >
              I have been clinically diagnosed with Parkinson's Disease.
            </label>
            <br />
            <button
              id="submitButton"
              type="button"
              className={`custom-button`}
              onClick={async () => await handleSubmit(prediction)}
            >
              Submit
            </button>
            <button
              type="button"
              className="custom-button"
              onClick={handleReset}
            >
              Reset
            </button>
            <button
              type="button"
              className="custom-button"
              onClick={() => handleOpenPastRecord()}
            >
              Past Results
            </button>
            <br />
            <p class="text-with-break">
              Prediction:{" "}
              {prediction ? <span>{prediction}</span> : <a>No Result.</a>}
            </p>
            <div>
              <LoadingBar
                style={{ height: "250%" }}
                color="#007958"
                progress={loadingBarProgress}
                onLoaderFinished={() => setLoadingBarProgress(0)}
              />
            </div>
          </div>
          <div
            style={{
              // flexGrow: 1,
              // display: "flex"
              float: "left"
            }}
          >
            <Popup
              contentStyle={{
                width: `420px`
              }}
              trigger={
                <button
                  style={{
                    transform: `scale(0.5)`,
                    float: "left"
                  }}
                >
                  <img src={infoLogo} />
                </button>
              }
            >
              {
                <div
                  style={{
                    width: "400px",
                    float: "left",
                    textAlign: "left"
                  }}
                >
                  <div class="text-with-break" style={{ margin: "40px" }}>
                    <h3>INSTRUCTIONS</h3>
                    <ol>
                      <li>
                        Starting from the center of the Spiral on the Canvas,
                        click and drag your mouse to draw over the dashed line
                        following the red arrows directions.
                      </li>
                      <li>Select the hand you drew with.</li>
                      <li>Select your dominant hand.</li>
                      <li>
                        If you have been clinically diagnosed with Parkinson's
                        Disease, click on the Checkbox.
                      </li>
                      <li>Click the Submit button to get your results.</li>
                    </ol>
                    <br />
                    Your results should be available after 1 minute.
                    <br />
                  </div>
                </div>
              }
            </Popup>
          </div>
        </div>
      </div>
    </div>
  )
}
