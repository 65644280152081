import React, { useEffect, useState, useRef } from "react"
import { FaBars } from "react-icons/fa"
import { IconContext } from "react-icons/lib"
import { animateScroll as scroll } from "react-scroll"
import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks
} from "./NavbarElements"

const Navbar = ({ toggle, isStatic }) => {
  const [scrollNav, setScrollNav] = useState(false)
  //const [access_token, setAccessToken] = useState(null);
  //const [refreshToken, setRefreshToken] = useState(null);
  const [username, setUsername] = useState("")
  const isFirstRender = useRef(true)
  //const username = localStorage.getItem("username");
  // const isStatic = isStatic

  const styleReg = { scrollNav: scrollNav, isStatic: isStatic }

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true)
    } else {
      setScrollNav(false)
    }
  }

  useEffect(() => {
    if (window.location.search.includes("code")) {
      handleAuthentication()
    }

    if (isFirstRender.current) {
      isFirstRender.current = false
      refreshAccessToken(localStorage.getItem("refresh_token"))
        .then((access_token) => {
          fetchUserInfo(access_token)
            .then((userInfo) => {
              if (userInfo.error) {
                console.log(
                  "Error: ",
                  userInfo.error,
                  "error_description: ",
                  userInfo.error_description
                )
              } else {
                // console.log("User info fetched successfully.")
                setUsername(userInfo.username)
              }
            })
            .catch((error) => {
              console.error("Error fetching user info:", error)
            })
        })
        .catch((error) => {
          console.error("Error fetching token:", error)
        })
    }

    window.addEventListener("scroll", changeNav)
  }, [])

  const toggleHome = () => {
    scroll.scrollToTop()
  }

  const handleLogin = () => {
    window.location.href =
      "https://neuroprior-users.auth.us-east-1.amazoncognito.com/login?client_id=7p9vmm4fmapn3a2sgim1e0f2hm&response_type=code&scope=email+openid+phone&redirect_uri=https%3A%2F%2Fwww.neuroprior.net"
  }

  const handleLogout = () => {
    localStorage.removeItem("refresh_token")
    window.location.reload()
  }

  // this function will be called when the user is redirected back to your app after successful login
  const handleAuthentication = async () => {
    const urlParams = new URLSearchParams(window.location.search)
    const access_token = urlParams.get("code")
    //clears urlParams after retrieving access_token (avoiding 2x call to useEffect)
    urlParams.delete("code")
    window.history.replaceState({}, document.title, window.location.pathname)

    //perform further actions with the access_token, such as storing it or using it for API requests
    //console.log('Code:', access_token);
    if (access_token) {
      try {
        // Exchange the authentication code for an access token
        const response = await fetch(
          "https://neuroprior-users.auth.us-east-1.amazoncognito.com/oauth2/token",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded"
            },
            body: new URLSearchParams({
              grant_type: "authorization_code",
              client_id: "7p9vmm4fmapn3a2sgim1e0f2hm", //'YOUR_CLIENT_ID'
              code: access_token,
              redirect_uri: "https://www.neuroprior.net" //'YOUR_REDIRECT_URI'
            })
          }
        )

        const data = await response.json()
        // console.log("Access Token:", data.access_token)
        // console.log("Refresh Token:", data.refresh_token) //used to periodically generate new access tokens
        //setAccessToken(data.access_token);
        //setRefreshToken(data.refresh_token);
        localStorage.setItem("refresh_token", data.refresh_token)
        // Retrieve user information using the access token
        fetchUserInfo(data.access_token)
        refreshAccessToken(localStorage.getItem("refresh_token"))
      } catch (error) {
        console.error("Error exchanging code for access token:", error)
      }
    }
  }

  const refreshAccessToken = async (refresh_token) => {
    try {
      const response = await fetch(
        "https://neuroprior-users.auth.us-east-1.amazoncognito.com/oauth2/token",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          },
          body: new URLSearchParams({
            grant_type: "refresh_token",
            client_id: "7p9vmm4fmapn3a2sgim1e0f2hm",
            refresh_token: refresh_token
          })
        }
      )

      const data = await response.json()
      // console.log("New Access Token:", data.access_token)
      return data.access_token
      //setAccessToken(data.access_token);
    } catch (error) {
      console.error("Error refreshing access token:", error)
    }
  }

  const fetchUserInfo = async (token) => {
    try {
      const response = await fetch(
        "https://neuroprior-users.auth.us-east-1.amazoncognito.com/oauth2/userInfo",
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      const userInfo = await response.json()
      // console.log('User Info:', userInfo);
      localStorage.setItem("username", userInfo.username)
      setUsername(userInfo.username) // userInfo.sub for userID!
      return userInfo
      //localStorage.setItem("username", userInfo.username);
    } catch (error) {
      console.error("Error fetching user info:", error)
    }
  }

  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <Nav styleReg={styleReg}>
          <NavbarContainer>
            {/* <NavLogo to="/" onClick={toggleHome}>NeuroPrior AI</NavLogo> */}
            <NavLogo as="a" href="https://neuroprior.org/">
              NeuroPrior AI
            </NavLogo>
            <MobileIcon onClick={toggle}>
              <FaBars />
            </MobileIcon>
            <NavMenu>
              {
                username ? (
                  <React.Fragment>
                    <NavItem>
                      <NavLinks
                        as="a"
                        smooth={true}
                        duration={500}
                        spy={true}
                        exact="true"
                        offset={-80}
                      >
                        Welcome {username}
                      </NavLinks>
                    </NavItem>
                    <NavItem>
                      <NavLinks
                        as="a"
                        onClick={handleLogout}
                        smooth={true}
                        duration={500}
                        spy={true}
                        exact="true"
                        offset={-80}
                      >
                        Logout
                      </NavLinks>
                    </NavItem>
                  </React.Fragment>
                ) : (
                  <NavItem>
                    <NavLinks
                      as="a"
                      onClick={handleLogin}
                      smooth={true}
                      duration={500}
                      spy={true}
                      exact="true"
                      offset={-80}
                    >
                      Login
                    </NavLinks>
                  </NavItem>
                )
                /* <NavItem>
                <NavLinks to="tests"
                  smooth={true} duration={500} spy={true} exact='true' offset={-80}
                >Tests</NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to="services"
                smooth={true} duration={500} spy={true} exact='true' offset={-80}
                >Services</NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to="research"
                smooth={true} duration={500} spy={true} exact='true' offset={-80}
                >Research</NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to="blog"
                smooth={true} duration={500} spy={true} exact='true' offset={-80}
                >Blog</NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to="about"
                smooth={true} duration={500} spy={true} exact='true' offset={-80}
                >About</NavLinks>
              </NavItem> */
              }
            </NavMenu>

            {/* <NavBtn>
              <NavBtnLink to='/signin'>Sign In</NavBtnLink>
            </NavBtn> */}
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  )
}
export default Navbar
// export { fetchUserInfo };
