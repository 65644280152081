export const TEST_ENDPOINT =
  "https://73ujtk7zjqa2ksjdaxkguorn4u0gcnpw.lambda-url.us-east-1.on.aws/"

export const LIST_PAST_TEST_INFO_ENDPOINT =
  "https://mmttdmkvmdjtrhxjktn3eeaxje0qyqsr.lambda-url.us-east-1.on.aws/"
export const DOWNLOAD_RECORD_ENDPOINT =
  "https://4uhz7t2tvmnz4l4nskw4mzpnxm0ntztr.lambda-url.us-east-1.on.aws/"

// only used in transmission lambda
export const INVALIDTOKEN = "invalid token"
export const INVALIDDATAFORMAT = "invalid data format"
export const INVALIDTIMESTAMP = "invalid timestamp"
export const APICONNECTIONERROR = "api connection error"
export const TESTPERFORMERROR = "test perform error"
export const UNKNOWNERROR = "unknown error"
