import { LIST_PAST_TEST_INFO_ENDPOINT } from "../../metadata"
import { refreshTokens } from "../Authentication/refresh"

/*
    Inputs: userId: user's id retrieved from API call after authentication
            type: testType ("video", "canvas")
    Returns: const pastPredictions = {
                predictions: predictions,
                timestamps: timestamps
            }
            each property is an array of predictions (float) and an array of timestamps (integer)
*/
const handleHistogram = async (userId, type) => {
  const data = {
    type: type,
    userId: userId,
    token: localStorage.getItem("access_token")
    //numRecord?: number // default 5
    //timeInterval?: TimeInterval
  }

  const resultRes = await fetch(LIST_PAST_TEST_INFO_ENDPOINT, {
    method: "POST",
    mode: "cors",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "POST, OPTIONS",
      "Access-Control-Allow-Headers": "Content-Type, Authorization",
      // "Access-Control-Allow-Credentials": true,
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  })
  if (resultRes.statusCode !== 200 && resultRes.status !== 200) {
    return
  }

  const pastPredictionsResponse = await resultRes.json()
  // multiply by 100 to get values in % and rounded to 2 decimal plates.
  const predictions = pastPredictionsResponse
    .map((prediction) => (parseFloat(prediction.prediction) * 100).toFixed(2))
    .reverse()
  // const timestamps = pastPredictionsResponse.map(prediction => {
  //     const date = new Date(prediction.timestamp);
  //     const month = date.getMonth() + 1; // Javascript's Date month is zero-based, so add 1
  //     const day = date.getDate();
  //     return `${month}/${day}`;
  //   }).reverse()
  const timestamps = pastPredictionsResponse
    .map((prediction) => prediction.timestamp)
    .reverse()

  const pastPredictions = {
    predictions: predictions,
    timestamps: timestamps
  }

  await refreshTokens()

  return pastPredictions
}

export { handleHistogram }
