export const homeObjOne = {
    id: 'tests',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Our Tests',
    headline: 'Select Any Body Part to Diagnose the Motor Functions',
    description: 'We are a benefit company that provide free online diagnosis of motor functions.',
    buttonLabel: 'Start Testing',
    imgStart: false,
    img: require('../../images/svg-2.svg').default,
    alt: 'Paper',
    dark: true,
    primary: true,
    darkText: false,
    // internalRoute: "/signin"
    externalLink: "https://www.neuroprior.com/"
};

export const homeObjTwo = {
    id: 'research',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: ' Our Research',
    headline: 'Develop a Neuroscience-Inspired AI',
    description: 'Our mission is to develop neuroscience-inspired AI and employ AI to benefit human health and research using prior knowledge of the brain and behavior.',
    buttonLabel: 'Learn More',
    imgStart: true,
    img: require('../../images/svg-1.svg').default,
    alt: 'Piggybank',
    dark: false,
    primary: false,
    darkText: true,
    // internalRoute: "/signin"
    externalLink: "https://www.neuroprior.com/"
};

export const homeObjFour = {
    id: 'blog',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: ' Our Blog',
    headline: 'Headline for blog and pending for update.',
    description: 'This is the main description for blog and pending for update.',
    buttonLabel: 'Enter Blog',
    imgStart: false,
    img: require('../../images/svg-6.svg').default,
    alt: 'Money',
    dark: true,
    primary: true,
    darkText: false,
    // internalRoute: "/signin"
    externalLink: "https://www.neuroprior.com/"
};

export const homeObjThree = {
    id: 'about',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Our Vision',
    headline: 'Open-Source AI Platform & Biomedical Research',
    description: 'Our vision is to be a pioneer in open-source AI-powered platforms that improve online diagnosis of motor functions and share software for biomedical research.',
    buttonLabel: 'Learn More',
    imgStart: true,
    img: require('../../images/svg-3.svg').default,
    alt: 'Car',
    dark: false,
    primary: false,
    darkText: true,
    // internalRoute: "/signin"
    externalLink: "https://www.neuroprior.com/"
};
