import { useReactMediaRecorder } from "react-media-recorder"
import { useEffect, useRef, useState } from "react"
import LoadingBar from "react-top-loading-bar"
import Popup from "reactjs-popup"
import { Drawer, Button } from "rsuite"
import "reactjs-popup/dist/index.css"
import "rsuite/dist/rsuite.min.css"
import { Buffer } from "buffer"

// @ts-ignore
window.Buffer = Buffer // allow GIFEncoder to work

import {
  TEST_ENDPOINT,
  LIST_PAST_TEST_INFO_ENDPOINT,
  DOWNLOAD_RECORD_ENDPOINT,
  INVALIDTOKEN
} from "../../metadata"
import { handlePresignURLUpload } from "../VideoUpload/VideoUpload"
import HistogramEmotionScore from "../Histogram/HistogramEmotionScore"
import { handleHistogram } from "../Histogram/handleHistogram"
import Histogram from "../Histogram/Histogram"
import "../../App.css"
import {
  refreshTokens,
  refreshTokensWithUserInfo
} from "../Authentication/refresh"
import Navbar from "../Navbar"
import infoLogo from "../../assets/info.svg"
import { logUserOut } from "../Authentication/logUserOut"

const GIFEncoder = require("gifencoder")

const VideoPreview = ({ stream }) => {
  const videoRef = useRef(null)

  useEffect(() => {
    if (videoRef.current && stream) {
      videoRef.current.srcObject = stream
    }
  }, [stream])
  if (!stream) {
    return null
  }
  return <video ref={videoRef} width={600} height={600} autoPlay controls />
}

export const Video = () => {
  const { status, startRecording, stopRecording, mediaBlobUrl, previewStream } =
    useReactMediaRecorder({ video: true })
  const [isRecording, setIsRecording] = useState(false)
  const [isRecorded, setIsRecorded] = useState(false)

  const [userId, setUserId] = useState("")

  const [prediction, setPrediction] = useState("")
  const [loadingBarProgress, setLoadingBarProgress] = useState(0)

  // const [parkinsonRecordList, setParkinsonRecordList] = useState([])
  // const [parkinsonRecordLoaded, setParkinsonRecordLoaded] = useState(false)

  const [isOpen, setIsOpen] = useState(false)

  // Drawer
  const [open, setOpen] = useState(false)
  const [openPastRecord, setOpenPastRecord] = useState(false)
  const [placement, setPlacement] = useState()

  const handleOpen = (key) => {
    setOpen(true)
    setPlacement(key)
  }

  const handleOpenPastRecord = () => {
    setOpenPastRecord(true)
  }

  const isFirstRender = useRef(true)
  const [data, setData] = useState([0, 0, 0, 0, 0])
  const [timestamps, setTimestamps] = useState([0, 0, 0, 0, 0])
  const [emotionScore, setEmotionScore] = useState({
    Parkinson: 0,
    angry: 0,
    disgust: 0,
    fear: 0,
    happy: 0,
    neutral: 0,
    sad: 0,
    surprise: 0
  })
  // const emotionScore = [0, 10, 20, 30, 40, 50]; //TODO: API call to retrieve and replace data with actual data
  useEffect(() => {
    // call authentication functions when component mounts
    if (isFirstRender.current) {
      isFirstRender.current = false
      refreshTokensWithUserInfo().then((sub) => {
        setUserId(sub)

        //populate histogram after user is Authenticated
        handleHistogram(sub, "video").then((predictions) => {
          setData(predictions.predictions)
          setTimestamps(predictions.timestamps)
        })
      })
    }
  }, [])

  const toggle = () => {
    setIsOpen(!isOpen)
  }
  const handleStartRecording = () => {
    setIsRecording(true)
    startRecording()
  }

  const handleStopRecording = () => {
    setIsRecording(false)
    if (status === "recording") setIsRecorded(true)
    stopRecording()
  }

  const handleGetPastResult = async () => {
    // const data = {
    //   type: "video",
    //   userId: userId,
    //   token: localStorage.getItem("access_token")
    // }

    // const testRes = await fetch(LIST_PAST_TEST_INFO_ENDPOINT, {
    //   method: "POST",
    //   mode: "cors",
    //   headers: {
    //     "Access-Control-Allow-Origin": "*",
    //     "Access-Control-Allow-Methods": "POST, OPTIONS",
    //     "Access-Control-Allow-Headers": "Content-Type, Authorization",
    //     // "Access-Control-Allow-Credentials": true,
    //     "Content-Type": "application/json"
    //   },
    //   body: JSON.stringify(data)
    // })
    // if (testRes.statusCode !== 200 && testRes.status !== 200) {
    //   alert("Error happens when loading previous records")
    //   return
    // }

    // const response = (await testRes.json()).map((record) => {
    //   const date = new Date(record.timestamp)
    //   return {
    //     ...record,
    //     prediction: `${(Number(record.prediction) * 100.0).toFixed(2)}%`,
    //     date: `${date.toLocaleDateString("en-US")} ${date.toLocaleTimeString(
    //       "en-US"
    //     )}`
    //   }
    // })

    const predicitons = await handleHistogram(userId, "video")
    setData(predicitons.predictions)
    setTimestamps(predicitons.timestamps)

    // setParkinsonRecordList(response)
    // setParkinsonRecordLoaded(true)
    await refreshTokens()
  }

  const handleClearPastResult = () => {
    // setParkinsonRecordList([])
    // setParkinsonRecordLoaded(false)
    setOpenPastRecord(false)
  }

  const handleDownload = async (timestamp) => {
    const data = {
      type: "video",
      userId: userId,
      token: localStorage.getItem("access_token"),
      timestamp: Number(timestamp)
    }

    const resultRes = await fetch(DOWNLOAD_RECORD_ENDPOINT, {
      method: "POST",
      mode: "cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "POST, OPTIONS",
        "Access-Control-Allow-Headers": "Content-Type, Authorization",
        // "Access-Control-Allow-Credentials": true,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    })
    if (resultRes.statusCode !== 200 && resultRes.status !== 200) {
      return
    }

    const files = await resultRes.json()

    const imgsVideo = []

    for (const file of files) {
      if (file.fileName !== "prediction.txt") {
        imgsVideo.push(file.fileBody)
      } else {
        const newBlob = new Blob([Buffer.from(file.fileBody)])
        const link = document.createElement("a")
        link.download = `prediction_${timestamp}.txt`
        link.style.display = "none"
        const blobUrl = window.URL.createObjectURL(newBlob)
        link.href = blobUrl
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        // clean up Url
        window.URL.revokeObjectURL(blobUrl)
      }
    }

    if (imgsVideo.length) {
      var canvas = document.createElement("canvas"),
        ctx = canvas.getContext("2d")

      canvas.width = 224
      canvas.height = 224

      const encoder = new GIFEncoder(224, 224, "neuquant")

      encoder.start()
      encoder.setRepeat(0)
      encoder.setDelay(500)
      for (const imgArray of imgsVideo) {
        // create imageData object
        // var idata = ctx.createImageData(224, 224)

        // // set our buffer as source
        // idata.data.set(imgArray)

        // // update canvas with new data
        // ctx.putImageData(idata, 0, 0)
        await new Promise((resolve) => {
          const image = new Image()
          image.onload = () => {
            ctx.drawImage(image, 0, 0)
            encoder.addFrame(ctx)
            resolve()
          }
          const newBlob = new Blob([Buffer.from(imgArray)])
          const blobUrl = window.URL.createObjectURL(newBlob)
          image.src = blobUrl
        })
        // const img64Base = new Buffer.from(imgArray).toString("base64")
        // console.log(img64Base)
        // ctx.drawImage(img64Base, 0, 0)
        // encoder.addFrame(ctx)
      }

      const buffer = encoder.out.getData()

      const newBlob = new Blob([buffer])
      const link = document.createElement("a")
      link.download = `face_meshed_${timestamp}.gif`
      link.style.display = "none"
      const blobUrl = window.URL.createObjectURL(newBlob)
      link.href = blobUrl
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      // clean up Url
      window.URL.revokeObjectURL(blobUrl)
    }

    await refreshTokens()
  }

  const blobToBase64 = (blob) => {
    const reader = new FileReader()
    reader.readAsDataURL(blob)
    return new Promise((resolve) => {
      reader.onloadend = () => {
        resolve(reader.result)
      }
    })
  }
  // rewrite this function!

  const handleUpload = async (prevPrediction) => {
    if (userId === "") {
      alert("Video was not uploaded!\nPlease login before uploading!")
      return
    } else if (userId === undefined) {
      alert("Video was not uploaded!\nPlease login before uploading!")
      return
    }
    if (isRecording) {
      alert("Please finish recording the video before uploading!")
      return
    }
    if (!isRecorded) {
      alert(
        "You have not recorded a video yet! \n If you have, check if your webcam works, close all other applications that use your webcam, and record again."
      )
      return
    }

    // remove previous prediction
    const previousResultToken =
      prevPrediction && prevPrediction[0] !== "L"
        ? ` (Previous prediction: ${prevPrediction})`
        : ""
    setPrediction(`Loading...${previousResultToken}`)

    // loadingBar.current.continuousStart()
    setLoadingBarProgress(loadingBarProgress + 10)

    const timestampUsed = Date.now()

    const videoTestDataPresignedURL = {
      timestamp: timestampUsed,
      userId: userId,
      videoFormat: "mp4"
      //filename : string,
      //numImages : number
    }

    const dataPresignedURL = {
      data: videoTestDataPresignedURL,
      userId: userId,
      type: "getvideouploadurl",
      token: localStorage.getItem("access_token")
    }

    try {
      //const video_data = await blobToBase64((await fetch(mediaBlobUrl)).blob())

      const presignurlRes = await fetch(TEST_ENDPOINT, {
        method: "POST",
        mode: "cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST, OPTIONS, PUT",
          "Access-Control-Allow-Headers": "Content-Type, Authorization",
          // "Access-Control-Allow-Credentials": true,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(dataPresignedURL)
      })

      const presignurlResult = await presignurlRes.json()
      const presignurlStatus = presignurlResult.statusCode

      if (presignurlStatus !== 200) {
        setPrediction("")
        setLoadingBarProgress(100)
        return
      }

      const presignurl = presignurlResult.body
      if (!presignurl) {
        setPrediction("")
        setLoadingBarProgress(100)
        return
      }

      // 30
      setLoadingBarProgress(loadingBarProgress + 20)
      const videoData = await (await fetch(mediaBlobUrl)).blob()
      // let metadata = {
      //   type: "video/mp4"
      // };
      // let file = new File([dataVideo], "test.mp4", metadata);

      // var a = document.createElement('a');
      // a.download = 'download.txt';
      // a.href = window.URL.createObjectURL(file);
      // a.click();

      const response = await handlePresignURLUpload(presignurl, videoData)

      if (!response) {
        setPrediction("")
        setLoadingBarProgress(100)
        return
      }

      setLoadingBarProgress(loadingBarProgress + 20)
      // call the server
      const videoTestData = {
        timestamp: timestampUsed,
        userId: userId,
        videoFormat: "mp4"
      }

      const dataVideoTest = {
        data: videoTestData,
        userId: userId,
        type: "video",
        username: localStorage.getItem("username"),
        token: localStorage.getItem("access_token")
      }

      const videoTestRes = await fetch(TEST_ENDPOINT, {
        method: "POST",
        mode: "cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST, OPTIONS",
          "Access-Control-Allow-Headers": "Content-Type, Authorization",
          // "Access-Control-Allow-Credentials": true,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(dataVideoTest)
      })

      const result = await videoTestRes.json()
      if (result.statusCode !== 200) {
        setPrediction("")
        setLoadingBarProgress(100)

        if (response.type === INVALIDTOKEN) {
          // sign out
          alert("Your session is expired! Please log-in again!")
          logUserOut()
        } else if (
          response.type === INVALIDDATAFORMAT ||
          response.type === INVALIDTIMESTAMP
        ) {
          alert("Please follow the instruction to complete the spiral drawing!")
          handleReset()
        } else if (
          response.type === APICONNECTIONERROR ||
          response.type === TESTPERFORMERROR
        ) {
          alert("Service is on maintainence right now, please come back later!")
        }

        return
      }

      setLoadingBarProgress(loadingBarProgress + 30)

      var prediction = result.body.acc
      // precentage
      prediction = (Number(prediction) * 100.0).toFixed(2)
      var evaluate = "Low"
      if (prediction >= 60) {
        evaluate = "High"
      } else if (prediction >= 30) {
        evaluate = "Moderate"
      }

      setPrediction(`${prediction}% - ${evaluate} possibility of Parkinson's`)

      setEmotionScore(result.body.emotion_score)

      setLoadingBarProgress(100)
      await refreshTokens()
    } catch (e) {
      setPrediction("")
      setLoadingBarProgress(100)
      return
    }

    // fetch(mediaBlobUrl)
    //   .then((res) => res.blob())
    //   .then((res) => blobToBase64(res))
    //   .then((res) =>
    //     fetch(
    //       "https://hdszvzxmji44uh46lfcjtoplmi0isvjw.lambda-url.ca-central-1.on.aws/",
    //       {
    //         method: "POST",
    //         headers: {
    //           "Content-Type": "video/mp4",
    //         },
    //         body: res,
    //       }
    //     )
    //       .then((res) => {
    //         return res.json();
    //       })
    //       .then((data) => {
    //         alert("Video has been uploaded.");
    //         console.log("Success:", data);
    //       })
    //       .catch((error) => {
    //         alert("Failed to upload video!");
    //         console.error("Error:", error);
    //       })
    //   );
  }
  // TODO: think about an alternative solution to base64 encoding (uploading raw blob(binary) with formdata???)

  return (
    <div
      style={{
        width: "100%"
      }}
      id="video_test"
    >
      <Drawer
        placement={placement}
        open={open}
        onClose={() => setOpen(false)}
        style={{ height: "400px" }}
      >
        <Drawer.Body
          style={{
            display: "flex",
            "margin-left": "auto",
            "margin-right": "auto"
          }}
        >
          <div
            style={{
              "margin-left": "auto",
              "margin-right": "auto"
            }}
          >
            <HistogramEmotionScore
              data={emotionScore}
              width="700px"
              height="800px"
            />
          </div>
        </Drawer.Body>
      </Drawer>

      <Drawer
        placement={"left"}
        open={openPastRecord}
        onClose={() => handleClearPastResult()}
        onOpen={async () => await handleGetPastResult()}
        style={{ width: "800px" }}
      >
        <Drawer.Header>
          <div>
            Please click on the bar or the date to download the corresponding
            record.
          </div>
        </Drawer.Header>
        <Drawer.Body>
          <Histogram
            data={data}
            timestamps={timestamps}
            width="600px"
            handleDownload={handleDownload}
          />
        </Drawer.Body>
      </Drawer>

      <Navbar
        style={{ width: "100%", height: "80px" }}
        toggle={toggle}
        isStatic={true}
      />
      <div style={{ width: "100%", height: "80px" }}></div>

      <div
        style={{
          display: "flex"
        }}
      >
        <div
          style={{
            "margin-left": "auto",
            "margin-right": "auto"
          }}
        >
          <div style={{ width: "600px", height: "600px", float: "left" }}>
            <p>{status}</p>
            <div style={{ display: `inline-block` }}>
              {isRecording && <VideoPreview stream={previewStream} />}
              {!isRecording && (
                <video
                  id="recorded"
                  src={mediaBlobUrl}
                  controls
                  autoPlay
                  loop
                  style={{ width: "600px", height: "475px" }}
                />
              )}
              {userId ? (
                <span></span>
              ) : (
                <a
                  class="text-with-break"
                  href="https://neuroprior-users.auth.us-east-1.amazoncognito.com/login?client_id=7p9vmm4fmapn3a2sgim1e0f2hm&response_type=code&scope=email+openid+phone&redirect_uri=https%3A%2F%2Fwww.neuroprior.net"
                >
                  You are not logged in.
                </a>
              )}
            </div>
            <br />
            <p class="text-with-break">
              To perform the Video Test please allow access to your device's
              Camera.
              <br />
            </p>
            <button
              className="custom-button"
              onClick={() => handleStartRecording()}
            >
              Start Recording
            </button>
            <button
              className="custom-button"
              onClick={() => handleStopRecording()}
            >
              Stop Recording
            </button>
            <button
              className="custom-button"
              onClick={async () => await handleUpload(prediction)}
            >
              Upload Video
            </button>
            <button
              type="button"
              className="custom-button"
              onClick={() => handleOpenPastRecord()}
            >
              Past Results
            </button>
            <br />
            <p class="text-with-break">
              Prediction:{" "}
              {prediction ? (
                <span>
                  {prediction}{" "}
                  <Button
                    appearance="ghost"
                    disabled={prediction[0] == "L"}
                    onClick={() => handleOpen("top")}
                    style={{ transform: "scale(0.8)" }}
                  >
                    Show Emotion Score
                  </Button>
                </span>
              ) : (
                <a>No Result.</a>
              )}
            </p>
            <div>
              <LoadingBar
                style={{ height: "250%" }}
                color="#007958"
                progress={loadingBarProgress}
                onLoaderFinished={() => setLoadingBarProgress(0)}
              />
            </div>
            <br />
          </div>
          <div
            style={{
              // flexGrow: 1,
              // display: "flex"
              float: "left"
            }}
          >
            <Popup
              contentStyle={{
                width: `420px`
              }}
              trigger={
                <button
                  style={{
                    transform: `scale(0.5)`,
                    float: "left"
                  }}
                >
                  <img src={infoLogo} />
                </button>
              }
            >
              {
                <div
                  style={{
                    width: "400px",
                    float: "left",
                    textAlign: "left"
                  }}
                >
                  <div class="text-with-break" style={{ margin: "40px" }}>
                    <h3>INSTRUCTIONS</h3>
                    <ol>
                      <li>
                        Please click the Start Recording button and make the
                        facial expressions shown below in that order.
                      </li>
                      <li>
                        Hold each expression for about 10 seconds before moving
                        on to the next one.
                      </li>
                      <li>
                        Once finished, click the Stop Recording button and then
                        click the Upload Video button.
                      </li>
                    </ol>
                    <br />
                    Your results should be available after 5 minutes.
                    <br />
                    <br />
                    Once recording, please make the following facial expressions
                    in this order:
                    <ul>
                      <li>Sad with a frown</li>
                      <li>Happy with a smile</li>
                      <li>Angry showing teeth</li>
                      <li>Neutral with no expression</li>
                    </ul>
                    <br />
                  </div>
                </div>
              }
            </Popup>
          </div>
        </div>
      </div>
    </div>
  )
}
