const fetchUserInfo = async (token) => {
    try {
      const response = await fetch('https://neuroprior-users.auth.us-east-1.amazoncognito.com/oauth2/userInfo', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const userInfo = await response.json();
      // console.log('User Info:', userInfo);
      // setUsername(userInfo.username); // userInfo.sub for userID!
      //localStorage.setItem("username", userInfo.username);
      return userInfo;
    } catch (error) {
      console.error('Error fetching user info:', error);
    }
  };

  export { fetchUserInfo };