import React from 'react'
import { Canvas } from '../components/Canvas/Canvas'

const CanvasTestPage = () => {
  return (
    <div className="App" style={{
      width: '100%'
    }}>
      <Canvas />
    </div>
    )
}

export default CanvasTestPage