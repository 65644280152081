const uploadVideo = async (url, data) => {
  return await fetch(url, {
    method: "PUT",
    headers: {
      // "Content-Length": new Blob([data]).size,
      "Content-Type": "multipart/form-data",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "PUT",
      "Access-Control-Allow-Headers": "Content-Type, Authorization"
    },
    body: data
  })
}

/**
 * url: Presigned URL
 * data: video data
 */
export const handlePresignURLUpload = async (url, data) => {
  try {
    if (await uploadVideo(url, data)) return true

    return false
  } catch (e) {
    return false
  }
}
