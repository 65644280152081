const refreshAccessToken = async (refresh_token) => {
    try {
      const response = await fetch('https://neuroprior-users.auth.us-east-1.amazoncognito.com/oauth2/token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          grant_type: 'refresh_token',
          client_id: '7p9vmm4fmapn3a2sgim1e0f2hm',
          refresh_token: refresh_token,
        }),
      });
  
      const data = await response.json();
      //console.log('New Access Token:', data.access_token);
      return data.access_token;
      //setAccessToken(data.access_token);
    } catch (error) {
      console.error('Error refreshing access token:', error);
    }
  };

  export { refreshAccessToken };