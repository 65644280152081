import React from 'react'
import { Video } from '../components/Video/Video';


const VideoTestPage = () => {
  return (
    <div className="App">
      <Video />
    </div>)
}

export default VideoTestPage