import { fetchUserInfo } from "./fetchUserInfo"
import { refreshAccessToken } from "./refreshAccessToken"
const refreshTokens = async () => {
  try {
    const access_token = await refreshAccessToken(
      localStorage.getItem("refresh_token")
    )
    localStorage.setItem("access_token", access_token)
  } catch (e) {
    console.error("Error refreshing token:", e)
  }
}

const refreshTokensWithUserInfo = async () => {
  try {
    const access_token = await refreshAccessToken(
      localStorage.getItem("refresh_token")
    )
    localStorage.setItem("access_token", access_token)
    const userInfo = await fetchUserInfo(access_token)
    if (!userInfo.error) {
      // we dont need to set manytimes
      localStorage.setItem("username", userInfo.username)
      return userInfo.sub
    } else {
      // logout
      console.error("Error fetching user info:", error)
    }
  } catch (e) {
    console.error("Error refreshing token:", e)
  }
}

export { refreshTokens, refreshTokensWithUserInfo }
