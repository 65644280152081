import React, { useEffect, useRef, useState } from "react"
import { Button } from "rsuite"
import Chart from "chart.js/auto"
import ChartDataLabels from "chartjs-plugin-datalabels"
import "../../App.css"
import $ from "jquery"

Chart.register(ChartDataLabels)

const Histogram = ({ data, timestamps, width, height, handleDownload }) => {
  const chartRef = useRef(null)
  const chartInstance = useRef(null)
  const [historyInterval, setHistoryInterval] = useState("notSelected")

  const [globalTimestamp, setGlobalTimestamp] = useState(0)

  useEffect(() => {
    if (chartInstance.current) {
      chartInstance.current.destroy() // destroy the previous instance
    }

    // determine default value for previous data to be displayed:
    let sliceIndex = data.length - 5
    if (historyInterval !== "notSelected") {
      if (historyInterval <= data.length) {
        sliceIndex = data.length - historyInterval
      } else {
        sliceIndex = 0
      }
    }

    setGlobalTimestamp(0)

    const ctx = chartRef.current.getContext("2d")

    const clickHandler = (evt) => {
      const points = chartInstance.current.getElementsAtEventForMode(
        evt,
        "nearest",
        { intersect: true },
        true
      )

      if (points.length) {
        const firstPoint = points[0]
        const label = chartInstance.current.data.labels[firstPoint.index]
        const value =
          chartInstance.current.data.datasets[firstPoint.datasetIndex].data[
            firstPoint.index
          ]
        setGlobalTimestamp(
          timestamps.slice(sliceIndex, timestamps.length)[firstPoint.index]
        )
      }
    }

    const findLabel = (labels, evt) => {
      let found = false
      let res = null
      labels.forEach((label) => {
        if (
          evt.x > label.x &&
          evt.x < label.x2 &&
          evt.y > label.y &&
          evt.y < label.y2
        ) {
          res = {
            label: label.label,
            index: label.index
          }
          found = true
        }
      })
      return [found, res]
    }

    const getLabelHitBoxes = (x) => {
      return x._labelItems.map((e, i) => ({
        x: e.options.translation[0] - 2 * x._labelSizes.widths[i],
        x2: e.options.translation[0] + 2 * x._labelSizes.widths[i],
        y: e.options.translation[1] - 2 * x._labelSizes.heights[i],
        y2: e.options.translation[1] + 2 * x._labelSizes.heights[i],
        label: e.label,
        index: i
      }))
    }

    chartInstance.current = new Chart(ctx, {
      type: "bar",
      plugins: [
        {
          datalabels: {
            display: true,
            color: "black",
            font: {
              weight: "bold"
            }
          }
        },
        {
          afterEvent: (chart, event, opts) => {
            const evt = event.event

            if (evt.type !== "click") {
              return
            }

            const [found, labelInfo] = findLabel(
              getLabelHitBoxes(chart.scales.x),
              evt
            )
            if (found) {
              setGlobalTimestamp(
                timestamps.slice(sliceIndex, timestamps.length)[labelInfo.index]
              )
            }
          }
        }
      ],
      data: {
        labels: timestamps
          .map((time) => {
            const date = new Date(time)
            const month = date.getMonth() + 1 // Javascript's Date month is zero-based, so add 1
            const day = date.getDate()
            return `${month}/${day}`
          })
          .slice(sliceIndex, data.length),
        datasets: [
          {
            label: "Prediction",
            data: data.slice(sliceIndex, data.length),
            backgroundColor: "rgba(54, 162, 235, 0.2)",
            borderColor: "rgba(54, 162, 235, 1)",
            borderWidth: 1,
            // fill: false,
            hoverBorderColor: "rgba(54, 162, 235, 1)",
            hoverBackgroundColor: "rgba(54, 162, 235, 0.7)",
            hoverBorderWidth: 2
          }
        ]
      },
      options: {
        layout: {
          padding: {
            left: 20,
            right: 20,
            top: 20,
            bottom: 20
          }
        },
        scales: {
          x: {
            title: {
              display: true,
              text: "Test Attempts (Month/Day)"
            }
          },
          y: {
            title: {
              display: true,
              text: "Diagnosis %"
            },
            onClick: clickHandler
          }
        },
        onClick: clickHandler,
        hover: {
          onHover: function (e) {
            $("#canvas").css("cursor", e[0] ? "pointer" : "default")
          }
        },
        onHover: function (e) {
          $("#canvas").css("cursor", e[0] ? "pointer" : "default")
        }
      }
    })

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy() // destroy the chart instance on component unmount
      }
    }
  }, [data, timestamps, historyInterval, width, height])

  return (
    <div style={{ width: width, height: height }}>
      <div>
        <canvas id="canvas" ref={chartRef} />
        <div>Set number of most recent history records</div>
        <br></br>
        <select
          className="custom-select"
          value={historyInterval}
          onChange={(e) => {
            setHistoryInterval(e.target.value)
          }}
          style={{ width: `40%`, float: `left`, marginLeft: `20%` }}
        >
          <option value="notSelected">Please Select</option>
          {/* Generate options for integers from 1 to 30 */}
          {[...Array(30).keys()].map((num) => (
            <option key={num + 1} value={num + 1}>
              {num + 1}
            </option>
          ))}
        </select>
      </div>
      <div style={{ float: "right" }}>
        <Button
          appearance="ghost"
          disabled={globalTimestamp == 0}
          onClick={() => handleDownload(globalTimestamp)}
        >
          Download
        </Button>
      </div>
    </div>
  )
}

export default Histogram
