import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import Icon1 from '../../images/svg-1.svg'
import Icon2 from '../../images/svg-2.svg'
import Icon3 from '../../images/svg-3.svg'
import Video from '../../videos/video2.mp4'
import { ScrollButton } from '../MainButtonElements'
import { HeroContainer, HeroBg, VideoBg, HeroContent, HeroH1, HeroP, HeroBtnWrapper, ArrowForward, ArrowRight, ServicesContainer, ServicesH1, ServicesWrapper, ServicesCard, ServicesIcon, ServicesH2, ServicesP } from './HeroElements'

const HeroSection = () => {
    const [hover, setHover] = useState(false)

    const onHover = () => {
        setHover(!hover)
    }

    return (
        <HeroContainer>
            <HeroBg>
                <VideoBg autoPlay loop muted src={Video} type='video2/mp4' />
            </HeroBg>
            <HeroContent>


                {/* <HeroH1>
                    Tests
                </HeroH1> */}


                {/* <HeroBtnWrapper>
                    <ScrollButton to="tests" smooth={true} duration={500} spy={true} exact='true' offset={-60} onMouseEnter={onHover} onMouseLeave={onHover} primary='true' dark='true'>
                        Start Testing {hover ? <ArrowForward /> : <ArrowRight />}
                    </ScrollButton>
                </HeroBtnWrapper> */}






                <ServicesContainer id="services">
                <ServicesH1>Our Services</ServicesH1>
                    <ServicesWrapper>
                        <ServicesCard>
                            <ServicesIcon src={Icon1} />
                            <ServicesH2>EEG Test</ServicesH2>
                            <ServicesP>Monitoring EEG brain waves.</ServicesP>
                        </ServicesCard>
                        <Link to="/canvastest" style={{ textDecoration: 'none' }}>
                            <ServicesCard>
                                <ServicesIcon src={Icon2} />
                                <ServicesH2>Drawing Test</ServicesH2>
                                <ServicesP>Evaluating hand fine motor function.</ServicesP>
                            </ServicesCard>
                        </Link>
                        <Link to="/videotest" style={{ textDecoration: 'none' }}>
                            <ServicesCard>
                                <ServicesIcon src={Icon3} />
                                <ServicesH2>Facial Test</ServicesH2>
                                <ServicesP>Facial expression 3D modeling and evaluation.</ServicesP>
                            </ServicesCard>
                        </Link>
                    </ServicesWrapper>
                </ServicesContainer>





            </HeroContent>
        </HeroContainer>
    )
}

export default HeroSection