import React, { useEffect, useRef } from "react"
import Chart from "chart.js/auto"

const HistogramEmotionScore = ({ data, width, height }) => {
  const chartRef = useRef(null)
  const chartInstance = useRef(null)

  var index = Object.keys(data).map((x) => x)
  var score = Object.values(data).map((x) => x)

  useEffect(() => {
    if (chartInstance.current) {
      chartInstance.current.destroy() // destroy the previous instance
    }

    const ctx = chartRef.current.getContext("2d")

    chartInstance.current = new Chart(ctx, {
      type: "bar",
      data: {
        labels: index,
        datasets: [
          {
            label: "Emotion Scores",
            data: score,
            backgroundColor: "rgba(54, 162, 235, 0.2)",
            borderColor: "rgba(54, 162, 235, 1)",
            borderWidth: 1
          }
        ]
      },
      options: {
        layout: {
          padding: {
            left: 20,
            right: 20,
            top: 20,
            bottom: 20
          }
        },
        scales: {
          x: {
            title: {
              display: true,
              text: "Emotions"
            }
          },
          // y: {
          //   title: {
          //     display: true,
          //     text: "Score"
          //   }
          // }
        }
      }
    })

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy() // destroy the chart instance on component unmount
      }
    }
  }, [data, width, height])

  return (
    <div style={{ width: width, height: height }}>
      <canvas ref={chartRef} />
    </div>
  )
}

export default HistogramEmotionScore
